<template>
  <el-table class="spec" size="mini" border :data="data">
    <el-table-column label="规格名称" prop="name" :width="100">
      <template #default="{ row }">
        <el-input v-model="row.name" size="mini" />
      </template>
    </el-table-column>
    <el-table-column label="规格值" prop="values">
      <template #default="{ row, $index }">
        <el-tag
          :key="tag"
          v-for="tag in row.values"
          closable
          class="m-r-10"
          :disable-transitions="false"
          @close="handleClose(row.values, tag, $index)"
        >
          {{ tag }}
        </el-tag>
        <el-input
          class="input-new-tag"
          v-model="row.inputValue"
          ref="saveTagInput"
          size="small"
          placeholder="回车确认"
          @keyup.enter.native="handleInputConfirm(row, $index)"
          @blur="handleInputConfirm(row, $index)"
        >
        </el-input>
      </template>
    </el-table-column>
    <el-table-column label="操作" :width="150" align="center">
      <template #default="{ $index }">
        <el-button
          v-if="$index == 0"
          size="mini"
          type="primary"
          @click="onCreateRule"
        >
          新增规格
        </el-button>
        <el-button
          v-else
          size="mini"
          type="danger"
          @click="onRemoveRule($index)"
        >
          删除规格
        </el-button>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
export default {
  name: "TabPane2Spec",
  props: { data: Array },
  data() {
    return {
      inputVisible: false,
      inputValue: "",
      index: 0,
    };
  },
  methods: {
    handleClose(arr, tag, index) {
      arr.splice(arr.indexOf(tag), 1);
      this.$emit("change-sku", { index, key: "values", values: arr });
    },

    showInput() {
      this.inputVisible = true;
      this.$nextTick(() => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },

    handleInputConfirm(row, index) {
      let inputValue = row.inputValue;

      if (row.values.indexOf(inputValue) !== -1) {
        this.$message.error("规格值不允许重复！");
        return;
      } else if (inputValue !== "") {
        row.values.push(inputValue);
        row.inputValue = "";
        this.$emit("change-sku", { index, key: "values", values: row.values });
      }
    },
    // 添加新规则
    onCreateRule() {
      this.index++;
      this.$emit("add-sku", { name: `规格${this.index}`, values: [] });
    },
    // 移除规则
    onRemoveRule(index) {
      this.$emit("remove-sku", index);
    },
  },
};
</script>

<style lang="scss" scoped>
.spec {
  width: 880px;
}
.el-tag + .el-tag {
  margin-left: 10px;
}
.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
</style>
