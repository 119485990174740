<template>
  <el-form-item label="价格库存" prop="goodsSpecificationAdd">
    <div>
      <el-button type="text" @click="onBatchUpdate('salesPrice')">
        批量修改销售价
      </el-button>
      <el-button type="text" @click="onBatchUpdate('originalPrice')">
        批量修改划线价
      </el-button>
      <el-button type="text" @click="onBatchUpdate('costPrice')">
        批量修改成本价
      </el-button>
      <el-button type="text" @click="onBatchUpdate('inventory')">
        批量修改库存
      </el-button>

      <el-button type="text" @click="onBatchUpdate('maxNumber')">
        批量修改购买上限
      </el-button>
      <el-button type="text" @click="onBatchUpdate('earlyWarning')">
        批量修改预警库存
      </el-button>
      <el-button type="text" @click="onBatchUpdate('weight')">
        批量修改重量
      </el-button>
      <el-button type="text" @click="onBatchUpdate('volume')">
        批量修改体积
      </el-button>

      <el-button type="text" @click="onBatchUpdate('integralPay')" v-show="model.isIntegralPay == 1">
        批量可使用积分
      </el-button>

    </div>
    <el-table row-key="subheading" default-expand-all :data="model.goodsSpecificationAdd" border>
      <el-table-column label="规则" prop="value"></el-table-column>
      <el-table-column label="副标题" prop="subheading">
        <!-- <template #default="{row}">
<span>{{row.subheading}}</span>
        </template> -->
      </el-table-column>
      <el-table-column label="SKU图片" prop="goodsSkuAdd.image" width="80px" align="center">
        <template #default="{ row }">
          <el-upload v-if="!row.children" action="api/file/upload" :show-file-list="false" :limit="1"
            :on-success="onUploadSuccess" :on-remove="onUploadRemove" @click.native="onCache(row)">
            <i v-if="!row.goodsSkuAdd.image" class="el-icon-plus"></i>
            <el-image v-else :src="`api/${row.goodsSkuAdd.image}`" fit="cover"></el-image>
          </el-upload>
        </template>
      </el-table-column>
      <el-table-column label="销售价" prop="goodsSkuAdd.salesPrice">
        <template #header="{ column }">
          <span style="color: red">*</span>{{ column.label }}
        </template>
        <template #default="{ row }">
          <el-input v-if="!row.children" v-model="row.goodsSkuAdd.salesPrice" size="mini" placeholder="销售价" />
        </template>
      </el-table-column>
      <el-table-column label="划线价" prop="goodsSkuAdd.originalPrice">
        <template #default="{ row }">
          <el-input v-if="!row.children" v-model="row.goodsSkuAdd.originalPrice" size="mini" placeholder="划线价" />
        </template>
      </el-table-column>
      <el-table-column label="成本价" prop="goodsSkuAdd.costPrice">
        <template #default="{ row }">
          <el-input v-if="!row.children" v-model="row.goodsSkuAdd.costPrice" size="mini" placeholder="成本价" />
        </template>
      </el-table-column>
      <el-table-column label="库存" prop="goodsSkuAdd.inventory">
        <template #header="{ column }">
          <span style="color: red">*</span>{{ column.label }}
        </template>
        <template #default="{ row }">
          <el-input v-if="!row.children" v-model="row.goodsSkuAdd.inventory" size="mini" placeholder="库存" />
        </template>
      </el-table-column>
      <el-table-column label="预警库存" prop="goodsSkuAdd.earlyWarning">
        <template #default="{ row }">
          <el-input v-if="!row.children" v-model="row.goodsSkuAdd.earlyWarning" size="mini" placeholder="预警库存" />
        </template>
      </el-table-column>

      <el-table-column label="购买上限" prop="goodsSkuAdd.maxNumber">
        <template #default="{ row }">
          <el-input v-if="!row.children" v-model="row.goodsSkuAdd.maxNumber" size="mini" placeholder="购买上限" />
        </template>
      </el-table-column>
      <el-table-column label="重量（g）" prop="goodsSkuAdd.weight">
        <template #default="{ row }">
          <el-input v-if="!row.children" v-model="row.goodsSkuAdd.weight" size="mini" placeholder="重量（g）" />
        </template>
      </el-table-column>
      <el-table-column label="体积（m³）" prop="goodsSkuAdd.volume">
        <template #default="{ row }">
          <el-input v-if="!row.children" v-model="row.goodsSkuAdd.volume" size="mini" placeholder="体积（m³）" />
        </template>
      </el-table-column>
      <el-table-column label="可使用积分" prop="goodsSkuAdd.integralPay" v-if="model.isIntegralPay == 1">
        <template #default="{ row }">
          <el-input v-if="!row.children" v-model="row.goodsSkuAdd.integralPay" size="mini" placeholder="可使用积分" />
        </template>
      </el-table-column>


      <el-table-column label="单位" prop="goodsSkuAdd.unit">
        <template #default="{ row }">


          <el-select v-if="!row.children" v-model="row.goodsSkuAdd.unit" size="mini" placeholder="">
            <el-option v-for="(item, index) in dicList" :key="index" :label="item.parameterName"
              :value="item.parameterName"></el-option>
          </el-select>
        </template>
      </el-table-column>


      <el-table-column label="是否默认" prop="goodsSkuAdd.isDefault" align="center">
        <template #default="{ row }">
          <el-switch v-if="!row.children" v-model="row.goodsSkuAdd.isDefault" active-color="#409eff" inactive-color="#ccc"
            :active-value="0" :inactive-value="1" @change="defaultChange(row)">
          </el-switch>
        </template>
      </el-table-column>
    </el-table>
  </el-form-item>
</template>

<script>
import request from "@/utils/request";
import api from "@/api/common";
export default {
  name: "TabPane2Table",
  props: { model: Object },
  data() {
    return {
      row: undefined,
      dicList: []
    };
  },
  mounted() {

    this.getUnitDic()
  },
  methods: {
    async getUnitDic() {


      let res = await api.commonGet({
        apiName: "/systemDataDictionaryItem",
        systemDataDictionaryId: "1670776725850021890",
        pageSize:500
      });
      this.dicList = res.data.data;

    },
    defaultChange(row) {

      this.$emit("isDefaultChange", row);

    },
    // 缓存选中的行
    onCache(row) {
      this.row = row;
    },
    // 上传成功
    async onUploadSuccess(response) {
      await this.$nextTick();
      this.row.goodsSkuAdd.image = response.data.path;
    },
    // 删除上传的图片
    async onUploadRemove() {
      await request.post("/file/remove", { path: this.row.goodsSkuAdd.image });
      this.row.goodsSkuAdd.image = undefined;
    },
    // 批量更新
    async onBatchUpdate(key) {
      try {
        const messages = { salesPrice: "销售价", inventory: "库存" };
        const message = messages[key];
        const { value } = await this.$prompt("请输入", "提示", {
          inputPlaceholder: message,
        });
        batchUpdate(this.model, key, value);
      } catch (error) {
        console.log(error);
      }

      function batchUpdate(model, key, value) {
        model.goodsSpecificationAdd.forEach(updateField);

        function updateField(item) {
          // 递归更新，只修改最后一级
          if (!item.children) {
            item.goodsSkuAdd[key] = value;
            return;
          }
          item.children.forEach(updateField);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-upload {
  display: flex;
  justify-content: center;
  align-items: center;
  border: thin dashed lightgray;
  width: 50px;
  height: 50px;
}
</style>
