<template>
  <div>
    <el-form-item label="启用多规则">
      <el-switch v-model="model.enable" @change="onChange" />
      <tab-pane-2-spec v-show="model.enable" :data="model.skuList" v-on="$listeners" />
    </el-form-item>
    <tab-pane-2-table v-if="model.enable" :model="model" v-on="$listeners" />
    <tab-pane-2-spec-single v-else :model="model" />
  </div>
</template>

<script>
import tabPane2Spec from "./tabPane2Spec";
import tabPane2Table from "./tabPane2Table";
import tabPane2SpecSingle from "./tabPane2SpecSingle";

export default {
  name: "TabPane2",
  components: { tabPane2Spec, tabPane2Table, tabPane2SpecSingle },
  props: { model: Object },
  data() {
    return {
      // 自增
      index: 0,
    };
  },
  // watch: {
  //   // 监听规则数组变化，重构树形结构
  //   "model.skuList": {
  //     handler(newValue) {
  //       // 至少要有一条规则
  //       if (newValue.length == 0) {
  //         return;
  //       }
  //       console.log(newValue);
  //       // 构建树形结构，规则值使用逗号分割
  //       const tree = newValue[0].values.map((value) => {
  //         return mapper(newValue[0].name, value, 1, "");
  //       });

  //       // 更新模型的树形结构
  //       this.model.goodsSpecificationAdd = tree;

  //       // 递归
  //       function mapper(name, value, deep, previousValue) {
  //         let subheading = previousValue + value;
  //         const last = deep >= newValue.length;
  //         const item = {
  //           name,
  //           value,
  //           subheading,
  //           goodsSkuAdd: {
  //             name: undefined,
  //             subheading,
  //             image: undefined,
  //             salesPrice: undefined,
  //             originalPrice: undefined,
  //             costPrice: undefined,
  //             inventory: undefined,
  //             earlyWarning: undefined,
  //             weight: undefined,
  //             volume: undefined,
  //           },
  //         };
  //         //  不是最后一层
  //         if (!last) {
  //           subheading += " ";
  //           item.goodsSkuAdd = undefined;
  //           const next = newValue[deep++];
  //           const children = next.values.map((val) =>
  //             mapper(next.name, val, deep, subheading)
  //           );

  //           return Object.assign(item, { subheading, children });
  //         } else {
  //           // 最后一层
  //           console.log(subheading);

  //           let obj = getTreeVal(subheading);
  //           console.log("obj", obj);

  //           return item;
  //         }
  //       }

  //       function getTreeVal(str) {
  //         console.log("str", str);
  //         for (
  //           let index = 0;
  //           index < this.model.goodsSpecificationAdd.length;
  //           index++
  //         ) {
  //           const element = this.model.goodsSpecificationAdd[index];

  //           let res = recursionTree(element, str);
  //           return res;
  //         }
  //       }

  //       function recursionTree(obj, str) {
  //         if (obj.children) {
  //           recursionTree(obj.children);
  //         } else {
  //           let res = obj.goodsSkuAdd.find((item) => {
  //             return item.subheading == str;
  //           });

  //           return res;
  //         }
  //       }
  //     },
  //     deep: true,
  //     immediate: true,
  //   },
  // },
  mounted() { },
  methods: {


    // 首次打开添加第1条空规则
    onChange(e) {

      console.log(this.model.enable);

      if (e && this.model.skuList.length == 0) {
        this.$emit("add-sku", { name: "规格0", values: [] });
      }
      else if (!e) {
        this.$emit("add-sku", false);
      }
    },
  },
};
</script>
