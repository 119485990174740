var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-table',{staticClass:"spec",attrs:{"size":"mini","border":"","data":_vm.data}},[_c('el-table-column',{attrs:{"label":"规格名称","prop":"name","width":100},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-input',{attrs:{"size":"mini"},model:{value:(row.name),callback:function ($$v) {_vm.$set(row, "name", $$v)},expression:"row.name"}})]}}])}),_c('el-table-column',{attrs:{"label":"规格值","prop":"values"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var $index = ref.$index;
return [_vm._l((row.values),function(tag){return _c('el-tag',{key:tag,staticClass:"m-r-10",attrs:{"closable":"","disable-transitions":false},on:{"close":function($event){return _vm.handleClose(row.values, tag, $index)}}},[_vm._v(" "+_vm._s(tag)+" ")])}),_c('el-input',{ref:"saveTagInput",staticClass:"input-new-tag",attrs:{"size":"small","placeholder":"回车确认"},on:{"blur":function($event){return _vm.handleInputConfirm(row, $index)}},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleInputConfirm(row, $index)}},model:{value:(row.inputValue),callback:function ($$v) {_vm.$set(row, "inputValue", $$v)},expression:"row.inputValue"}})]}}])}),_c('el-table-column',{attrs:{"label":"操作","width":150,"align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var $index = ref.$index;
return [($index == 0)?_c('el-button',{attrs:{"size":"mini","type":"primary"},on:{"click":_vm.onCreateRule}},[_vm._v(" 新增规格 ")]):_c('el-button',{attrs:{"size":"mini","type":"danger"},on:{"click":function($event){return _vm.onRemoveRule($index)}}},[_vm._v(" 删除规格 ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }