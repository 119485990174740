<template>
  <div class="single">
    <el-form-item label="销售价" prop="goodsSkuAdd.salesPrice" :rules="[
      { required: true, message: '销售价不能为空' },
      { type: 'number', message: '销售价必须为数字值' }
    ]">
      <el-input-number v-model.number="model.goodsSkuAdd.salesPrice" placeholder="请输入销售价" controls-position="right"
        :min="0" />
    </el-form-item>
    <el-form-item label="划线价" prop="goodsSkuAdd.originalPrice">
      <el-input-number v-model.number="model.goodsSkuAdd.originalPrice" placeholder="请输入划线价" controls-position="right"
        :min="0" />
    </el-form-item>
    <el-form-item label="成本价" prop="costPrice">
      <el-input-number v-model.number="model.goodsSkuAdd.costPrice" placeholder="成本价" controls-position="right"
        :min="0" />
    </el-form-item>
    <el-form-item label="库存" prop="goodsSkuAdd.inventory" :rules="[
      { required: true, message: '库存不能为空' },
      { type: 'number', message: '库存必须为数字值' }
    ]">
      <el-input-number v-model.number="model.goodsSkuAdd.inventory" placeholder="请输入库存" controls-position="right"
        :min="0" />
    </el-form-item>
    <el-form-item label="购买上限" prop="goodsSkuAdd.maxNumber">
      <el-input-number v-model.number="model.goodsSkuAdd.maxNumber" placeholder="单个购买上限" controls-position="right"
        :min="1" />
    </el-form-item>
    <el-form-item label="预警库存" prop="goodsSkuAdd.earlyWarning">
      <el-input-number v-model.number="model.goodsSkuAdd.earlyWarning" placeholder="请输入预警库存" controls-position="right"
        :min="0" />
    </el-form-item>

    <el-form-item label="重量（g）" prop="goodsSkuAdd.weight">
      <el-input-number v-model.number="model.goodsSkuAdd.weight" placeholder="请输入重量（g）" controls-position="right"
        :min="0" />
    </el-form-item>
    <el-form-item label="体积（m³）" prop="goodsSkuAdd.volume">
      <el-input-number v-model.number="model.goodsSkuAdd.volume" placeholder="请输入体积（m³）" controls-position="right"
        :min="0" />
    </el-form-item>

    <el-form-item label="可使用积分" prop="goodsSkuAdd.integralPay" v-show="model.isIntegralPay == 1">
      <el-input-number v-model.number="model.goodsSkuAdd.integralPay" placeholder="请输入可使用积分" controls-position="right"
        :min="0" />
    </el-form-item>
    <el-form-item label="单位" prop="goodsSkuAdd.unit">
      <el-select v-model="model.goodsSkuAdd.unit" placeholder="">
        <el-option v-for="(item, index) in dicList" :key="index" :label="item.parameterName"
          :value="item.parameterName"></el-option>
      </el-select>

    </el-form-item>



  </div>
</template>

<script>
import api from "@/api/common";
export default {
  name: 'TabPaneSpecSingle',
  props: { model: Object },
  data() {

    return {
      dicList: [],
      salesPriceRule: {

        trigger: ['blur', 'change'],
        message: '销售价不能为空'
      },
      inventoryRule: {
        trigger: ['blur', 'change'],
        message: '库存不能为空'
      }
    };
  },
  mounted() {

    this.getUnitDic()

    console.log("this.model",this.model);
  },
  methods: {
    async getUnitDic() {


      let res = await api.commonGet({
        apiName: "/systemDataDictionaryItem",
        systemDataDictionaryId: "1670776725850021890",
        pageSize:500
      });
      this.dicList = res.data.data;
      console.log('res.data.data', res.data.data);
    },
  },
};
</script>

<style lang="scss" scoped>
.single {
  width: 400px;
}
</style>
